
ul.menu-list {
    list-style: none;
    margin: 0 0 0 15px;
    padding: 0;
    .d-tr {
        border: 1px solid #66B1FF;
        height: 40px;
        line-height: 40px;
        text-align: center;
        margin-bottom: 10px;
        div {
            display: none;
        }
        &::before {
            content: '\677e\5f00\9f20\6807\653e\5230\6b64\4f4d\7f6e';
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #B3D8FF;
            color: #409EFF;
        }
    }
    .list-item {
        background: #fff;
        cursor: move;
    }
    li.menu-chapter-list {
        .chapter-content {
            height: 42px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 1px solid #c1c1c1;
            margin-bottom: 15px;
            .item-content {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding-left: 10px;
                flex: 1;
                .section_index {
                    width: 70px;
                }
                i {
                    font-size: 16px;
                    margin-right: 5px;
                }
            }
            .item-actions {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .icon-btn-expand, .icon-btn {
                    cursor: pointer;
                    font-size: 14px;
                    padding: 0 12px;
                    i {
                        margin-right: 5px;
                    }

                }
            }
        }
    }
}

